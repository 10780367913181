
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployee from '@/mixins/mixinEmployee';
import join from "@/vuex/employee/join";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import CommonProgress from "@/components/common/Progress.vue";
import EmployeeTableJoinControl from "@/components/employee/table/Join/Control.vue";
import EmployeeTableJoinTable from "@/components/employee/table/Join/Table.vue";
import EmployeePagerFoot from "@/components/employee/pager/Foot.vue";

@Component({
  components: {
    CommonProgress,
    EmployeeTableJoinControl,
    EmployeeTableJoinTable,
    EmployeePagerFoot,
  }
})
export default class Main extends Mixins(mixinEmployee) {
}
