
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinEmployee from "@/mixins/mixinEmployee";
  import { EmployeeHeaderData, BranchHeaderData, CompanyHeaderData } from "@/typesold/header";
  import CommonTab from "@/components/common/Tab.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonProgress from "@/components/common/Progress.vue";
  import EmployeeTableNowMain from "@/components/employee/table/Now/Main.vue";
  import EmployeeTableOrderMain from "@/components/employee/table/Order/Main.vue";
  import EmployeeTableJoinMain from "@/components/employee/table/Join/Main.vue";
  import EmployeeTableRetireMain from "@/components/employee/table/Retire/Main.vue";
  import EmployeeDialogJoin from "@/components/employee/dialog/Join.vue";
  import EmployeeDialogJoinmail from "@/components/employee/dialog/Joinmail.vue";
  import EmployeeDialogJoinmailexcel from "@/components/employee/dialog/Joinmailexcel.vue";
  import EmployeeDialogJoinmanual from "@/components/employee/dialog/Joinmanual.vue";
  import EmployeeDialogJoinexcel from "@/components/employee/dialog/Joinexcel.vue";
  import EmployeeDialogResendmail from "@/components/employee/dialog/Resendmail.vue";

  @Component({
    components: {
      CommonTab,
      CommonSelect,
      CommonProgress,
      EmployeeTableNowMain,
      EmployeeTableOrderMain,
      EmployeeTableJoinMain,
      EmployeeTableRetireMain,
      EmployeeDialogJoin,
      EmployeeDialogJoinmail,
      EmployeeDialogJoinmailexcel,
      EmployeeDialogJoinmanual,
      EmployeeDialogJoinexcel,
      EmployeeDialogResendmail,
    },
  })
  export default class Main extends Mixins(mixinEmployee) {
    get tab_options() {
      const options = {};
      options["now"] = "在職者";
      options["order"] = "申請中";
      options["join"] = "入社手続中";
      options["retire"] = "退職者";
      return options;
    }

    change_tab(tab) {
      this.tab = tab;
    }

    get is_ordered() {
      const header = this.header as BranchHeaderData | CompanyHeaderData;
      return (
        header.is_demand_overtime ||
        header.is_demand_holiday ||
        header.is_demand_punch ||
        header.is_demand_change
      );
    }
    get is_joined() {
      const header = this.header as BranchHeaderData | CompanyHeaderData;
      return header.is_join;
    }
  }
