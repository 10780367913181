
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployee from '@/mixins/mixinEmployee';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Row extends Mixins(mixinEmployee) {
  @Prop() each_order;

  get datestr() {
    const dateobj = util.date2obj(this.each_order.date);
    return util.formatDate(dateobj, 'yyyy年mm月dd日');
  }
  get employee() {
    return this.employee_hash[this.each_order.data.employee_id];
  }

  confirm_order() {
    if (this.each_order.kind == 0) {//変更
      this.change_page('employee_demand', {employee_id: this.each_order.data.employee_id});
    } else if (this.each_order.kind == 1) {//休日
      this.tmp_demand_holiday = util.deep_copy(this.each_order.data);
      if (this.tmp_demand_holiday.changed_date === null)
        Vue.set(this.tmp_demand_holiday, 'changed_date', this.each_order.data.date);
      var kind = this.tmp_demand_holiday.changed_kind ?? this.each_order.data.kind;
      if (this.tmp_demand_holiday.changed_kind === null)
        Vue.set(this.tmp_demand_holiday, 'changed_kind', this.each_order.data.kind);
      if (kind == 4 && this.tmp_demand_holiday.changed_hourly_paidhol === null)
        Vue.set(this.tmp_demand_holiday, 'changed_hourly_paidhol', this.each_order.data.hourly_paidhol);
      this.m.setIsOpenDemandHolidayDialog(true);
    } else if (this.each_order.kind == 2) {//残業
      this.tmp_demand_overtime = util.deep_copy(this.each_order.data);
      if ((this.tmp_demand_overtime.kind == 1 || this.tmp_demand_overtime.kind == 2) && this.tmp_demand_overtime.changed_started_at === null)
        Vue.set(this.tmp_demand_overtime, 'changed_started_at', this.each_order.data.started_at);
      if ((this.tmp_demand_overtime.kind == 0 || this.tmp_demand_overtime.kind == 2) && this.tmp_demand_overtime.changed_ended_at === null)
        Vue.set(this.tmp_demand_overtime, 'changed_ended_at', this.each_order.data.ended_at);
      this.m.setIsOpenDemandOvertimeDialog(true);
    } else if (this.each_order.kind == 3) {//打刻
      this.change_page('demand_punch', {employee_id: this.each_order.data.employee_id, attendance_date: this.each_order.yearmonth});
    }
  }

  delete_order() {
    if (this.each_order.kind == 0) {//変更
      this.m.deleteDemandChange({ch_employee_id: this.each_order.data.id, branch_id: this.viewing_branch});
    } else if (this.each_order.kind == 1) {//休日
      this.m.deleteDemandHoliday({demand_holiday_id: this.each_order.data.id, branch_id: this.viewing_branch});
    } else if (this.each_order.kind == 2) {//残業
      this.m.deleteDemandOvertime({demand_overtime_id: this.each_order.data.id, branch_id: this.viewing_branch});
    } else if (this.each_order.kind == 3) {//打刻
      this.m.deleteDemandPunch({demand_punch_id: this.each_order.data.id, branch_id: this.viewing_branch});
    }
  }

  get tmp_demand_holiday() {
    return this.m.demand_holiday;
  }
  set tmp_demand_holiday(val) {
    this.m.setDemandHoliday(val);
  }
  get tmp_demand_overtime() {
    return this.m.demand_overtime;
  }
  set tmp_demand_overtime(val) {
    this.m.setDemandOvertime(val);
  }
}
