
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinEmployee from "@/mixins/mixinEmployee";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";

  @Component({
    components: {
      UtilPopup,
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(mixinEmployee) {
    get is_display() {
      return this.m.is_open_join_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenJoinDialog(val);
    }

    open_join_mail_dialog() {
      this.is_display = false;
      this.m.setIsOpenJoinMailDialog(true);
    }

    change_plan() {
      this.change_page("OrderChangePlan");
    }
    open_join_manual_dialog() {
      if (this.header.plan == 0 && this.global.employee_num + 1 > 10) {
        dialog.openForceConfirmDialog({
          title: "従業員数が上限に達しました",
          msg: "現在ご利用中のプランAでは11人以上の従業員を登録することはできません。<br>BプランかCプランへ変更のうえ、再度社員登録を行ってください。",
          cancel_button: "キャンセル",
          exec_button: "プラン変更",
          func: this.change_plan,
          args: {},
        });
      } else {
        this.is_display = false;
        this.m.setIsOpenJoinManualDialog(true);
      }
    }
    open_join_excel_dialog() {
      this.is_display = false;
      this.m.setIsOpenJoinExcelDialog(true);
    }
  }
