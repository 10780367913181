
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinEmployee from '@/mixins/mixinEmployee';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonTime from "@/components/common/Time.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    UtilPopup,
    CommonTime,
    CommonButton,
  }
})
export default class Main extends Mixins(mixinEmployee) {
  get is_display() {
    return this.m.is_open_demand_overtime_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenDemandOvertimeDialog(val);
  }

  get tmp_demand_overtime() {
    return this.m.demand_overtime;
  }
  set tmp_demand_overtime(val) {
    this.m.setDemandOvertime(val);
  }

  admit_demand_overtime() {
    this.m.admitDemandOvertime({branch_id: this.viewing_branch, demand_overtime_id: this.m.demand_overtime.id, is_changed: this.is_changed});
  }
  deny_demand_overtime() {
    this.m.denyDemandOvertime({branch_id: this.viewing_branch, demand_overtime_id: this.m.demand_overtime.id});
  }

  get is_changed() {
    if (this.tmp_demand_overtime.kind == 1 || this.tmp_demand_overtime.kind == 2) {
      return this.tmp_demand_overtime.changed_started_at === null
        || this.tmp_demand_overtime.started_at != this.tmp_demand_overtime.changed_started_at;
    } else if (this.tmp_demand_overtime.kind == 0 || this.tmp_demand_overtime.kind == 2) {
      return this.tmp_demand_overtime.changed_ended_at === null
        || this.tmp_demand_overtime.ended_at != this.tmp_demand_overtime.changed_ended_at;
    }
  }
  get exec_button_string() {
    return !this.is_display || this.is_changed ? '修正承認' : '承認';
  }
}
