
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployee from '@/mixins/mixinEmployee';
import order from "@/vuex/employee/order";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import CommonProgress from "@/components/common/Progress.vue";
import EmployeeTableOrderControl from "@/components/employee/table/Order/Control.vue";
import EmployeeTableOrderTable from "@/components/employee/table/Order/Table.vue";
import EmployeePagerFoot from "@/components/employee/pager/Foot.vue";
import EmployeeTableOrderDialogDemandholiday from "@/components/employee/table/Order/dialog/Demandholiday.vue";
import EmployeeTableOrderDialogDemandovertime from "@/components/employee/table/Order/dialog/Demandovertime.vue";

@Component({
  components: {
    CommonProgress,
    EmployeeTableOrderControl,
    EmployeeTableOrderTable,
    EmployeePagerFoot,
    EmployeeTableOrderDialogDemandholiday,
    EmployeeTableOrderDialogDemandovertime,
  }
})
export default class Main extends Mixins(mixinEmployee) {
}
