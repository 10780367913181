
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployee from '@/mixins/mixinEmployee';
import now from "@/vuex/employee/now";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import CommonProgress from "@/components/common/Progress.vue";
import EmployeeTableNowControl from "@/components/employee/table/Now/Control.vue";
import EmployeeTableNowTable from "@/components/employee/table/Now/Table.vue";
import EmployeePagerFoot from "@/components/employee/pager/Foot.vue";

@Component({
  components: {
    CommonProgress,
    EmployeeTableNowControl,
    EmployeeTableNowTable,
    EmployeePagerFoot,
  }
})
export default class Main extends Mixins(mixinEmployee) {
}
