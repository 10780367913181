
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import mixinEmployee from '@/mixins/mixinEmployee';
import global from "@/vuex/employee/global";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonSelect,
  }
})
export default class Branchselector extends Mixins(mixinEmployee) {
  get branch_hash() {
    return this.global.branch_hash;
  }
}
