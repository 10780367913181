
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinEmployee from '@/mixins/mixinEmployee';
import EmployeeMain from "@/components/employee/Main.vue";

@Component({
  components: {
    EmployeeMain,
  }
})
export default class Employee extends Mixins(mixinEmployee) {

}
