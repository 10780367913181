
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployee from '@/mixins/mixinEmployee';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import EmployeeTableRetireRow from "@/components/employee/table/Retire/Row.vue";

@Component({
  components: {
    EmployeeTableRetireRow,
  }
})
export default class Table extends Mixins(mixinEmployee) {
}
