
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinEmployee from '@/mixins/mixinEmployee';
import * as util from '@/util';
import validate from "@/validations/validate";
import {validationEmployeeManual} from "@/validations/employee";
import UtilPopup from "@/components/util/Popup.vue";
import CommonText from "@/components/common/Text.vue";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    UtilPopup,
    CommonText,
    CommonSelect,
  }
})
export default class Main extends Mixins(mixinEmployee) {
  get is_display() {
    return this.m.is_open_join_manual_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenJoinManualDialog(val);
  }

  created() {
    this.tmp_employee = {
      name: null,
      kana: null,
      mail: null,
      code: null,
      hiring_type: 0,
      branch_id: this.viewing_branch == 0 ? this.s_branch_id : this.viewing_branch,
    }
  }

  @Watch('viewing_branch')
  onChangeViewingBranch() {
    this.tmp_employee = {
      name: null,
      kana: null,
      mail: null,
      code: null,
      hiring_type: 0,
      branch_id: this.viewing_branch == 0 ? this.s_branch_id : this.viewing_branch,
    }
  }

  get tmp_employee() {
    return this.m.join_manual_employee;
  }
  set tmp_employee(val) {
    this.m.setJoinManualEmployee(val);
  }

  get use_branch_hash() {
    var branch_hash = util.deep_copy(this.branch_hash);
    delete branch_hash[0];
    return branch_hash;
  }


  vue_set(key, val) {
    Vue.set(this.tmp_employee, key, val);
  }

  create_join_manual_employee() {
    if (validate(validationEmployeeManual(this.tmp_employee))) {
      this.is_display = false;
      this.change_page('employee_add', {
        add_employee_name: this.tmp_employee.name,
        add_employee_kana: this.tmp_employee.kana,
        add_employee_mail: this.tmp_employee.mail,
        add_employee_code: this.tmp_employee.code,
        add_employee_hiring_type: this.tmp_employee.hiring_type,
        add_employee_branch_id: this.tmp_employee.branch_id,
      });
    }
  }
}
