
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinEmployee from "@/mixins/mixinEmployee";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/employee/manager";
  import EmployeeTableNowRow from "@/components/employee/table/Now/Row.vue";

  @Component({
    components: {
      EmployeeTableNowRow,
    },
  })
  export default class Table extends Mixins(mixinEmployee) {}
