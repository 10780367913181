
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployee from '@/mixins/mixinEmployee';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";

import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {

    CommonButton,
  }
})
export default class Row extends Mixins(mixinEmployee) {
  @Prop() employee;
}
