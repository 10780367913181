
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import global from "@/vuex/shift/global";
  import mixinEmployee from "@/mixins/mixinEmployee";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonButton from "@/components/common/Button.vue";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";

  @Component({
    components: {
      UtilPopup,
      CommonButton,
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(mixinEmployee) {
    excel_file = null;
    get refs(): any {
      return this.$refs;
    }

    get is_display() {
      return this.m.is_open_join_excel_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenJoinExcelDialog(val);
    }

    select_file(e) {
      e.preventDefault();
      const files = e.target.files;
      this.excel_file = files[0];
      if (this.excel_file) {
        let name = this.excel_file.name.split(".");
        name = name[name.length - 1];
        if (name != "xlsx") {
          if (name == "xls") {
            dialog.openErrorAlertDialog({
              msg: "古いExcel(.xlsファイル)は使用できません。",
              detail: "フォームをダウンロードして使用してください。",
            });
          } else {
            dialog.openErrorAlertDialog({
              msg: "Excelファイルのみアップロード可能です。",
              detail: ".xlsxファイルのみ使用可能です。",
            });
          }
          this.excel_file = null;
          this.refs.employee_ref.reset_file();
        }
      }
    }

    async create_shift_excel() {
      await this.m.createEmployeeExcel({ excel: this.excel_file });
      this.excel_file = null;
      this.refs.employee_ref.reset_file();
    }
  }
