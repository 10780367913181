
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployee from '@/mixins/mixinEmployee';
import retire from "@/vuex/employee/retire";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import CommonProgress from "@/components/common/Progress.vue";
import EmployeeTableRetireControl from "@/components/employee/table/Retire/Control.vue";
import EmployeeTableRetireTable from "@/components/employee/table/Retire/Table.vue";
import EmployeePagerFoot from "@/components/employee/pager/Foot.vue";

@Component({
  components: {
    CommonProgress,
    EmployeeTableRetireControl,
    EmployeeTableRetireTable,
    EmployeePagerFoot,
  }
})
export default class Main extends Mixins(mixinEmployee) {
}
