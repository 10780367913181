
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import XLSX from "xlsx";
  import dialog from "@/vuex/dialog";
  import global from "@/vuex/shift/global";
  import mixinEmployee from "@/mixins/mixinEmployee";
  import * as util from "@/util";
  import validate from "@/validations/validate";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonButton from "@/components/common/Button.vue";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";

  @Component({
    components: {
      UtilPopup,
      CommonButton,
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(mixinEmployee) {
    excel_file = null;

    get refs(): any {
      return this.$refs;
    }

    get is_display() {
      return this.m.is_open_joinmail_excel_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenJoinmailExcelDialog(val);
    }

    get yearmonthstr() {
      return util.formatDate(util.date2obj(this.date), "yyyy年mm月");
    }

    select_file(e) {
      e.preventDefault();
      const files = e.target.files;
      this.excel_file = files[0];
      if (this.excel_file) {
        let name = this.excel_file.name.split(".");
        name = name[name.length - 1];
        if (name != "xlsx") {
          if (name == "xls") {
            dialog.openErrorAlertDialog({
              msg: "古いExcel(.xlsファイル)は使用できません。",
              detail: "フォームをダウンロードして使用してください。",
            });
          } else {
            dialog.openErrorAlertDialog({
              msg: "Excelファイルのみアップロード可能です。",
              detail: ".xlsxファイルのみ使用可能です。",
            });
          }
          this.excel_file = null;
          this.refs.joinmail_ref.reset_file();
        }
      }
    }

    import_excel() {
      if (!this.excel_file) {
        dialog.openErrorAlertDialog({ msg: "ファイルを選択してください。" });
      } else {
        dialog.openConfirmDialog({
          msg: "現在入力されている入社メールの送信予定者は上書きされますがよろしいですか？",
          cancel_button: "実行しない",
          exec_button: "実行する",
          func: this.import_excel_process,
          args: {},
        });
      }
    }

    async import_excel_process({}) {
      const buffer = await this.excel_file.arrayBuffer();
      const workbook = XLSX.read(buffer, { type: "buffer", bookVBA: true });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      const new_data = [];
      let is_error = false;
      let i = 3;
      for (i = 3; ; i++) {
        const error = [];
        const employee = data[i];
        if (!employee) break;
        if (
          employee["セイ"] !== undefined ||
          employee["メイ"] !== undefined ||
          employee["姓"] !== undefined ||
          employee["名"] !== undefined ||
          employee["メールアドレス"] !== undefined ||
          employee["社員コード"] !== undefined ||
          employee["バイト"] !== undefined ||
          employee["社員"] !== undefined ||
          employee["役員"] !== undefined ||
          employee["その他"] !== undefined
        ) {
          if (employee["セイ"] === undefined) error.push(`${i + 2}行目のセイが入力されていません`);
          if (employee["メイ"] === undefined) error.push(`${i + 2}行目のメイが入力されていません`);
          if (employee["姓"] === undefined) error.push(`${i + 2}行目の姓が入力されていません`);
          if (employee["名"] === undefined) error.push(`${i + 2}行目の名が入力されていません`);
          if (employee["メールアドレス"] === undefined)
            error.push(`${i + 2}行目のメールアドレスが入力されていません`);
          if (employee["社員コード"] === undefined)
            error.push(`${i + 2}行目の社員コードが入力されていません`);
          let hiring_type_count = 0;
          let hiring_type = null;
          if (employee["バイト"] !== undefined) {
            hiring_type_count++;
            hiring_type = 0;
          }
          if (employee["社員"] !== undefined) {
            hiring_type_count++;
            hiring_type = 1;
          }
          if (employee["役員"] !== undefined) {
            hiring_type_count++;
            hiring_type = 2;
          }
          if (employee["その他"] !== undefined) {
            hiring_type_count++;
            hiring_type = 3;
          }
          if (hiring_type_count == 0)
            error.push(
              `${i + 2}行目の区分(アルバイト・社員など)のいずれにもが入力されていません。`,
            );
          else if (hiring_type_count != 1)
            error.push(`${i + 2}行目の区分(アルバイト・社員など)が複数選択されています。`);

          let type_count = 0;
          let type = null;
          if (employee["全て使用(デフォルト)"] !== undefined) {
            type_count++;
            type = 0;
          }
          if (employee["シフト使用"] !== undefined) {
            type_count++;
            type = 1;
          }
          if (employee["シフト・勤怠使用"] !== undefined) {
            type_count++;
            type = 2;
          }
          if (employee["給与賞与使用"] !== undefined) {
            type_count++;
            type = 3;
          }
          if (employee["給与賞与年末調整使用"] !== undefined) {
            type_count++;
            type = 4;
          }
          if (employee["年末調整使用"] !== undefined) {
            type_count++;
            type = 5;
          }
          if (type_count == 0) type = 0;
          else if (type_count > 1) error.push(`${i + 2}行目の用途が複数選択されています。`);

          if (validate(error)) {
            employee["hiring_type"] = hiring_type;
            employee["type"] = type;
            new_data.push(employee);
          } else {
            is_error = true;
            break;
          }
        }
      }

      if (new_data.length == 0) {
        dialog.openErrorAlertDialog({
          msg: "情報がありません。",
          detail: "Excelファイルを編集後に保存されているかを確認してください。",
        });
      } else {
        if (!is_error) {
          let index = 0;
          this.deleteTmpEmployees();
          new_data.forEach((employee) => {
            Vue.set(this.tmp_employees, index++, {
              type: employee["type"],
              name: `${employee["姓"]} ${employee["名"]}`,
              kana: `${employee["セイ"]} ${employee["メイ"]}`,
              mail: employee["メールアドレス"],
              code: employee["社員コード"],
              hiring_type: employee["hiring_type"],
              branch_id: this.s_branch_id,
            });
          });

          this.is_display = false;
        }
      }
      this.resetFile();
    }

    deleteTmpEmployees() {
      this.tmp_employees.splice(0, this.tmp_employees.length);
    }

    resetFile() {
      this.excel_file = null;
      this.refs.joinmail_ref.reset_file();
    }

    get tmp_employees() {
      return this.m.join_mail_employees;
    }
    set tmp_employees(val) {
      this.m.setJoinMailEmployees(val);
    }
  }
