
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinEmployee from '@/mixins/mixinEmployee';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    UtilPopup,
    CommonText,
  }
})
export default class Main extends Mixins(mixinEmployee) {
  get is_display() {
    return this.m.is_open_resend_mail_dialog !== 0;
  }
  set is_display(val: boolean) {
    if (!val)
      this.m.setIsOpenResendMailDialog(0);
  }

  get tmp_mail() {
    return this.m.resend_mail;
  }
  set tmp_mail(val: string) {
    this.m.setResendMail(val);
  }

  async update_mail() {
    await this.m.resendMail();
  }

}
