
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinEmployee from "@/mixins/mixinEmployee";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/employee/manager";
  import EmployeeTableBranchselector from "@/components/employee/table/Branchselector.vue";
  import EmployeePagerHead from "@/components/employee/pager/Head.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      EmployeeTableBranchselector,
      EmployeePagerHead,
      CommonButton,
    },
  })
  export default class Control extends Mixins(mixinEmployee) {
    toBundleChangePage() {
      this.change_page("BundleChange");
    }
  }
