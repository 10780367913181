
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinEmployee from '@/mixins/mixinEmployee';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonDate from "@/components/common/Date.vue";
import CommonSelect from "@/components/common/Select.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    UtilPopup,
    CommonDate,
    CommonSelect,
    CommonButton,
  }
})
export default class Main extends Mixins(mixinEmployee) {
  get is_display() {
    return this.m.is_open_demand_holiday_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenDemandHolidayDialog(val);
  }

  get tmp_demand_holiday() {
    return this.m.demand_holiday;
  }
  set tmp_demand_holiday(val) {
    this.m.setDemandHoliday(val);
  }

  admit_demand_holiday() {
    this.m.admitDemandHoliday({branch_id: this.viewing_branch, demand_holiday_id: this.m.demand_holiday.id, is_changed: this.is_changed});
  }
  deny_demand_holiday() {
    this.m.denyDemandHoliday({branch_id: this.viewing_branch, demand_holiday_id: this.m.demand_holiday.id});
  }

  get is_changed() {
    return this.tmp_demand_holiday.changed_date === null
      || this.tmp_demand_holiday.changed_kind === null
      || util.date2obj(this.tmp_demand_holiday.date).getTime() != util.date2obj(this.tmp_demand_holiday.changed_date).getTime()
      || this.tmp_demand_holiday.kind != this.tmp_demand_holiday.changed_kind
      || (this.tmp_demand_holiday.changed_kind == 4 && this.tmp_demand_holiday.hourly_paidhol != this.tmp_demand_holiday.changed_hourly_paidhol);
  }
  get exec_button_string() {
    return !this.is_display || this.is_changed ? '修正承認' : '承認';
  }
}
